<template>
      <v-container fluid class="ma-0 pa-0 clear-background ">
      
      <!-- Header -->

        
          <v-layout class="primary-background">
              <div class="max-width-1200 ma-auto">

              <v-flex class="pa-6 ma-8">
                  <p class="antenna font-74 white--text mb-0" :class="{'font-37': $vuetify.breakpoint.xsOnly}">{{$ml.get('contact_title')}}</p>
                  <p class="avenir font-18 white--text mb-0">{{ $ml.get('contact_header_text') }}</p>
                  <p class="avenir font-18 white--text">{{ $ml.get('contact_header_text2') }}</p>
              </v-flex>

              <v-layout row wrap ma-8>
                    <v-flex sm4 pa-2>
                        <v-card class="clear-background" outlined flat style="border-radius: 0px; height:100%">
                              <v-layout column wrap ma-8>
                                  <v-flex style="display:flex">
                                        <p v-on:click="(mexico = true), (brasil = false)" class="antenna font-16 primary-color mb-9 clickable" :class="{'secondary-color underline': mexico}">Mexico</p>
                                        <p class="antenna font-16 primary-color mb-9 mx-4">|</p>
                                        <p v-on:click="(mexico = false), (brasil = true)" class="antenna font-16 primary-color mb-9 clickable" :class="{'secondary-color underline': brasil}">Brasil</p>
                                  </v-flex>
                                  <div v-if="mexico">
                                    <p class="antenna font-23 primary-color mb-2">{{ $ml.get('contact_location_title') }}</p>
                                    <p class="avenir font-20 secondary-color mb-1">Monterrey, México</p>  
                                    <p class="avenir font-16 primary-color">Torre Pabellón M, Av. Juarez 1102, Piso 36, Centro, 64000 Monterrey, N.L.</p>  
                                    <p class="antenna font-23 primary-color mb-2">{{ $ml.get('contact_detail_title') }}</p>
                                    <p class="avenir font-16 primary-color mb-0">contacto@driveop.com</p>
                                    <p class="avenir font-16 primary-color mb-0">01 81 5980 1223</p>
                                    <p class="avenir font-16 primary-color">9:00 am - 6:30 pm</p>
                                  </div>
                                  <div v-if="brasil">
                                    <p class="antenna font-23 primary-color mb-2">{{ $ml.get('contact_location_title') }}</p>
                                    <p class="avenir font-20 secondary-color mb-1">São Paulo, Brazil</p>  
                                    <p class="avenir font-16 primary-color">Av. Brg. Faria Lima, 1993 - 2a andar, Sala 25 - Jardim Paulistano São Paulo</p>  
                                    <p class="antenna font-23 primary-color mb-2">{{ $ml.get('contact_detail_title') }}</p>
                                    <p class="avenir font-16 primary-color mb-0">contacto@driveop.com</p>
                                    <p class="avenir font-16 primary-color mb-0">01 452 001</p>
                                    <p class="avenir font-16 primary-color">9:00 am - 6:00 pm</p>
                                  </div>
                              </v-layout>
                        </v-card>
                    </v-flex>
                    <v-flex sm8 pa-2>
                        <v-card class="clear-background" outlined flat style="border-radius: 0px; height:100%">
                            <section class="distribution-map" style="padding:10px">
                                <img class="change-my-color" src="../assets/img/images/world-map2.svg" style="height:auto;">
                                <button v-if="mexico" class="map-point project" style="top: 57.5%; left: 19%;"/>
                                <button v-if="brasil" class="map-point project" style="top: 78%; left: 32%;"/>
                            </section>
                        </v-card>
                    </v-flex>
                </v-layout>
            </div>
          </v-layout>

          <v-layout row wrap mb-0>
              <div class="max-width-1200 ma-auto">
              <v-layout row wrap>
              <v-flex offset-md-3 md6 style="text-align:center">
                  <div class="pa-6 mt-12">
                      <p class="antenna font-28 primary-color mb-0" :class="{'font-24': $vuetify.breakpoint.xsOnly}">{{ $ml.get('contact_text') }}</p>
                  </div>
              </v-flex>

              <v-flex xs12 mx-4>
                  <iframe id='my_typeform' src='https://juanpaulo.typeform.com/to/BRnz4c' data-hide-headers=true data-hide-footer=true class="typeform"></iframe>
              </v-flex>
              </v-layout>
              </div>
              
          </v-layout>

      </v-container>
</template>

<script>
export default {
    data () {
        return {
            map: require('../assets/img/images/world-map2.svg'),
            mexico: true,
            brasil: false
        }
    }
}
</script>

<style>


 .project {
  background: rgb(26,60,79) !important;
  border: rgb(26,60,79) !important;
}

.typeform {
    width: 100%; 
    height: calc(70vh - 55px); 
    border:none;
}

@media screen and (max-width: 600px) {
  .typeform {
    height: calc(50vh - 55px); 
    }
}
</style>
